import { render, staticRenderFns } from "./LatestStories.vue?vue&type=template&id=da3eecd6&scoped=true"
import script from "./LatestStories.vue?vue&type=script&lang=js"
export * from "./LatestStories.vue?vue&type=script&lang=js"
import style0 from "./LatestStories.vue?vue&type=style&index=0&id=da3eecd6&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da3eecd6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesSectionHeading: require('/src/components/molecules/SectionHeading.vue').default,MoleculesCardStory: require('/src/components/molecules/card/Story.vue').default,AtomsLabel: require('/src/components/atoms/Label.vue').default,MoleculesVisual: require('/src/components/molecules/Visual.vue').default,AtomsStoryMeta: require('/src/components/atoms/StoryMeta.vue').default,AtomsButton: require('/src/components/atoms/Button.vue').default,AtomsContainer: require('/src/components/atoms/Container.vue').default,AtomsModuleWrapper: require('/src/components/atoms/ModuleWrapper.vue').default})
