
import { nullable, oneOf, oneOfType, string } from 'vue-types';
import { COLOR_SCHEME_LIGHT, COLOR_SCHEME_BLUE } from '@/constants/General';

export default {
  inheritAttrs: false,
  props: {
    heading: oneOfType([string(), nullable()]),
    subheading: oneOfType([string(), nullable()]),
    colorScheme: oneOf([COLOR_SCHEME_BLUE, COLOR_SCHEME_LIGHT]).def(
      COLOR_SCHEME_LIGHT
    ),
  },
  data() {
    return {
      COLOR_SCHEME_LIGHT,
    };
  },
};
