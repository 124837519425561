export function formatAuthors(authorNames = []) {
  if (!authorNames?.length) {
    return '';
  }

  if (authorNames.length === 1) {
    return authorNames[0];
  }

  return authorNames.reduce((acc, author, index) => {
    if (index === 0) {
      return author;
    }

    const separator = index === authorNames.length - 1 ? ' & ' : ', ';

    return `${acc}${separator}${author}`;
  }, '');
}

export function formatAuthorsFromEntries(authorEntries) {
  const authorNames = authorEntries?.map((author) => author.title);
  return formatAuthors(authorNames);
}
