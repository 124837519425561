
import { arrayOf, number, shape, string } from 'vue-types';
import { COLOR_SCHEME_BLUE, SIZE_EXTRA_SMALL } from '~/constants/General';

import { formatAuthorsFromEntries } from '~/utils/authors';

export default {
  props: {
    authorEntry: arrayOf(
      shape({
        title: string(),
      }).loose
    ),
    languages: arrayOf(string()),
    readTime: number(),
    postDate: string(),
  },
  data() {
    return {
      SIZE_EXTRA_SMALL,
      COLOR_SCHEME_BLUE,
    };
  },
  computed: {
    renderLanguageLabel() {
      return (
        this.languages &&
        this.languages.length !== 0 &&
        !this.languages.includes(this.$i18n.locale)
      );
    },
    formattedAuthors() {
      return formatAuthorsFromEntries(this.authorEntry);
    },
  },
};
