
import {
  arrayOf,
  nullable,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'vue-types';
import { COLOR_SCHEME_LIGHT, SIZE_SMALL } from '~/constants/General';
import { categoryShape, entryShape, imageShape } from '~/constants/PropTypes';
import latestStoriesQuery from '~/gql/queries/components/latestStories.gql';

export default {
  inheritAttrs: false,
  props: {
    nextBg: string(),
    heading: oneOfType([string(), nullable()]),
    featuredStory: arrayOf(
      shape({
        ...entryShape,
        authorEntry: arrayOf(object()),
        postDate: oneOfType([string(), nullable()]),
        readTime: number(),
        category: arrayOf(shape(categoryShape).loose),
        visual: arrayOf(shape(imageShape).loose),
        languages: arrayOf(string()),
      }).loose
    ),
    category: arrayOf(shape(categoryShape).loose),
  },
  data() {
    return {
      stories: [],
      COLOR_SCHEME_LIGHT,
      SIZE_SMALL,
    };
  },
  async fetch() {
    const result = await this.$cms.query({
      query: latestStoriesQuery,
      variables: {
        site: this.$i18n.locale,
        limit: 4,
        categoryId: this.$first(this.category)?.id,
      },
    });
    this.stories = result?.stories || [];
  },
};
