
import { arrayOf, nullable, number, oneOfType, shape, string } from 'vue-types';
import breakpoints from '~/_tailwind/tailwind.config.breakpoints';
import { categoryShape, entryShape, imageShape } from '~/constants/PropTypes';

export default {
  inheritAttrs: false,
  props: {
    ...entryShape,
    authorEntry: arrayOf(
      shape({
        title: oneOfType([string(), nullable()]),
      }).loose
    ),
    languages: arrayOf(string()),
    postDate: oneOfType([string(), nullable()]),
    readTime: number().def(3),
    category: arrayOf(shape(categoryShape).loose),
    visual: arrayOf(shape(imageShape).loose),
  },
  computed: {
    labelSize() {
      const { width } = this.$store.state.viewport;
      const { s, m, l } = breakpoints;

      return (width >= s && width < m) || width >= l ? 'medium' : 'small';
    },
  },
};
