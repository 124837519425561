
import { bool, oneOf } from 'vue-types';
import {
  SIZE_EXTRA_SMALL,
  SIZE_SMALL,
  SIZE_MEDIUM,
  SIZE_LARGE,
  COLOR_SCHEME_DARK,
  COLOR_SCHEME_LIGHT,
  COLOR_SCHEME_BLUE,
  COLOR_SCHEME_YELLOW,
  COLOR_SCHEME_GREEN,
  COLOR_SCHEME_RED,
  COLOR_SCHEME_GRAY,
  COLOR_SCHEME_TURQUOISE,
} from '@/constants/General';

export default {
  inheritAttrs: false,
  props: {
    size: oneOf([SIZE_EXTRA_SMALL, SIZE_SMALL, SIZE_MEDIUM, SIZE_LARGE]).def(
      SIZE_MEDIUM
    ),
    colorScheme: oneOf([
      COLOR_SCHEME_DARK,
      COLOR_SCHEME_LIGHT,
      COLOR_SCHEME_BLUE,
      COLOR_SCHEME_YELLOW,
      COLOR_SCHEME_GREEN,
      COLOR_SCHEME_RED,
      COLOR_SCHEME_GRAY,
      COLOR_SCHEME_TURQUOISE,
    ]).def(COLOR_SCHEME_DARK),
    square: bool().def(false),
  },
  computed: {
    classes() {
      return {
        [this.size]: this.size,
        square: this.square,
        'rounded rounded-full': !this.square,
        'text-5xs': this.size === SIZE_EXTRA_SMALL,
        'text-3xs': this.size === SIZE_SMALL,
        'text-3xs s:text-xs':
          this.size === SIZE_MEDIUM || this.size === SIZE_LARGE,
        'bg-blue-100 text-blue-950': this.colorScheme === COLOR_SCHEME_BLUE,
        'bg-turquoise-500 text-blue-950':
          this.colorScheme === COLOR_SCHEME_LIGHT ||
          this.colorScheme === COLOR_SCHEME_TURQUOISE,
        'bg-dark-500 text-white': this.colorScheme === COLOR_SCHEME_DARK,

        'bg-yellow-400 text-blue-950': this.colorScheme === COLOR_SCHEME_YELLOW,
        'bg-green-500 text-blue-950': this.colorScheme === COLOR_SCHEME_GREEN,
        'bg-red-300 text-blue-950': this.colorScheme === COLOR_SCHEME_RED,
        'bg-gray-300 text-blue-950': this.colorScheme === COLOR_SCHEME_GRAY,
      };
    },
  },
};
