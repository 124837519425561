import { render, staticRenderFns } from "./StoryMeta.vue?vue&type=template&id=4de998d4"
import script from "./StoryMeta.vue?vue&type=script&lang=js"
export * from "./StoryMeta.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsLabel: require('/src/components/atoms/Label.vue').default})
