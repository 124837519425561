import { render, staticRenderFns } from "./Story.vue?vue&type=template&id=6d8b318f&scoped=true"
import script from "./Story.vue?vue&type=script&lang=js"
export * from "./Story.vue?vue&type=script&lang=js"
import style0 from "./Story.vue?vue&type=style&index=0&id=6d8b318f&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d8b318f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesVisual: require('/src/components/molecules/Visual.vue').default,AtomsLabel: require('/src/components/atoms/Label.vue').default,AtomsStoryMeta: require('/src/components/atoms/StoryMeta.vue').default})
